import React, { useEffect, useState } from 'react';
import { PieChart } from 'react-minimal-pie-chart';
import {
    IconFacebook,
    IconInstagram,
    IconTwitter,
    IconWeb,
    IconYoutube,
} from '../SVG/Icons';
import './styles.scss';

function TotalSocial({ title = '', data = [], sociais = [] }) {
    const [newData, setNewData] = useState([]);

    function gerar_cor(social) {
        if (social === 'facebook') return '#2e89ff';
        if (social === 'twitter') return '#2aa9e0';
        if (social === 'youtube') return '#f00';
        if (social === 'instagram') return '#a034aa';
        if (social === 'web') return '#CCC';
        if (social === 'tiktok') return '#00eee8';
        if (social === 'tumblr') return '#2e4c69';
        if (social === 'reddit') return '#ff4400';
        if (social === 'flickr') return '#f70080';
        if (social === 'dailymotion') return '#666';
        if (social === 'vimeo') return '#1ab7ea';
        if (social === 'vkontakte') return '#2787f5';
    }

    useEffect(() => {
        const dataFilter = [];

        const totalRede = {
            title: 'Redes sociais',
            chart: [],
        };
        const totalCompartilhamentos = {
            title: 'Compartilhamentos',
            chart: [],
        };
        const totalCurtidas = {
            title: 'Curtidas',
            chart: [],
        };
        const totalComentarios = {
            title: 'Comentários',
            chart: [],
        };

        sociais.map((row, key) => {
            var total = {
                title: row,
                value: data.filter(
                    (obj) => obj.RedeSocial.toLowerCase() === row.toLowerCase()
                ).length,
                color: gerar_cor(row),
            };
            totalRede.chart.push(total);

            //total de compartilhamentos por rede social
            var count = 0;
            data.filter(
                (obj) => obj.RedeSocial.toLowerCase() === row.toLowerCase()
            ).map((rowSocial) => {
                count = count + rowSocial.Compartilhamentos;
            });
            var total = {
                title: row,
                value: count,
                color: gerar_cor(row),
            };
            totalCompartilhamentos.chart.push(total);

            //total de curtidas por rede social
            var count = 0;
            data.filter(
                (obj) => obj.RedeSocial.toLowerCase() === row.toLowerCase()
            ).map((rowSocial) => {
                count = count + rowSocial.Curtidas;
            });
            var total = {
                title: row,
                value: count,
                color: gerar_cor(row),
            };
            totalCurtidas.chart.push(total);

            //total de comentarios por rede social
            var count = 0;
            data.filter(
                (obj) => obj.RedeSocial.toLowerCase() === row.toLowerCase()
            ).map((rowSocial) => {
                count = count + rowSocial.Comentarios;
            });
            var total = {
                title: row,
                value: count,
                color: gerar_cor(row),
            };
            totalComentarios.chart.push(total);
        });

        dataFilter.push(totalRede);
        dataFilter.push(totalCompartilhamentos);
        dataFilter.push(totalCurtidas);
        dataFilter.push(totalComentarios);

        setNewData(dataFilter);
    }, [data, sociais]);

    return (
        <div className="chart-sentimental">
            <div className="title-post">
                <h3>{title}</h3>
            </div>
            <div className="data">
                {newData.map((row, key) => (
                    <div className="item" key={key}>
                        <div className="header">
                            <p>{row.title}</p>
                        </div>

                        <div className="chart">
                            <PieChart
                                data={row.chart}
                                label={({ dataEntry }) =>
                                    `${Math.round(dataEntry.percentage)} %`
                                }
                                animate={true}
                                labelStyle={{
                                    fontSize: '5px',
                                }}
                            />

                            <div className="legends">
                                {row.chart.map((resposta, respostaKey) => (
                                    <div className="legend">
                                        <i
                                            style={{
                                                backgroundColor: resposta.color,
                                            }}
                                        ></i>
                                        <span>
                                            {resposta.title} ({resposta.value})
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default TotalSocial;
