import React, { useEffect, useState } from "react";

import { Scrollbars } from "react-custom-scrollbars";

import Profile from "./Profile";
import SideBar from "./Sidebar";

import "./Layout.scss";
import { useGlobal } from "../../Context/GlobalContext";

export default function Layout(props) {
  const { page } = useGlobal();
  const { user } = useGlobal()

  const [menuOpened, setMenuOpened] = useState(true);

  const [menus, setMenus] = useState([])

  useEffect(() => {


    if ( user.NivelAcesso === 'Administrador' ) {
      setMenus([
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              role="img"
              width="1em"
              height="1em"
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 32 32"
            >
              <path
                d="M4 20v2h4.586L2 28.586L3.414 30L10 23.414V28h2v-8H4z"
                fill="currentColor"
              />
              <path d="M24 21h2v5h-2z" fill="currentColor" />
              <path d="M20 16h2v10h-2z" fill="currentColor" />
              <path d="M16 18h2v8h-2z" fill="currentColor" />
              <path
                d="M28 2H4a2.002 2.002 0 0 0-2 2v12h2v-3h24.001l.001 15H16v2h12a2.003 2.003 0 0 0 2-2V4a2.002 2.002 0 0 0-2-2zm-16 9H4V4h8zm2 0V4h14v7z"
                fill="currentColor"
              />
            </svg>
          ),
          name: "Dashboard",
          to: "/",
        },
        {
          icon: (
            <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 32 32"><path d="M4 20v2h4.586L2 28.586L3.414 30L10 23.414V28h2v-8H4z" fill="currentColor"></path><path d="M24 21h2v5h-2z" fill="currentColor"></path><path d="M20 16h2v10h-2z" fill="currentColor"></path><path d="M16 18h2v8h-2z" fill="currentColor"></path><path d="M28 2H4a2.002 2.002 0 0 0-2 2v12h2v-3h24.001l.001 15H16v2h12a2.003 2.003 0 0 0 2-2V4a2.002 2.002 0 0 0-2-2zm-16 9H4V4h8zm2 0V4h14v7z" fill="currentColor"></path></svg>
          ),
          name: "Nova Campanha",
          to: "/nova-campanha",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              role="img"
              width="1em"
              height="1em"
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 20 20"
            >
              <path
                fill="currentColor"
                d="M10 0c5.523 0 10 4.477 10 10s-4.477 10-10 10S0 15.523 0 10S4.477 0 10 0zm.667 1.359v1.035a.667.667 0 0 1-1.334 0V1.359A8.614 8.614 0 0 0 5.637 2.51l.522.584a.667.667 0 0 1-.995.888l-.63-.707a8.714 8.714 0 0 0-1.776 1.962l.843.506a.667.667 0 0 1-.686 1.143l-.803-.481a8.607 8.607 0 0 0-.709 2.491h.907a.667.667 0 1 1 0 1.334l-.973-.001v.031a8.627 8.627 0 0 0 .742 3.263l.836-.559a.667.667 0 0 1 .741 1.109l-.939.627A8.66 8.66 0 0 0 10 18.667a8.662 8.662 0 0 0 7.447-4.23l-1.132-.757a.667.667 0 0 1 .74-1.109l.989.661a8.633 8.633 0 0 0 .62-3.003H17.58a.667.667 0 0 1 0-1.333h1.017a8.608 8.608 0 0 0-.57-2.168l-.95.492a.667.667 0 1 1-.612-1.184l.965-.5a8.71 8.71 0 0 0-1.839-2.158l-.602.789a.667.667 0 1 1-1.06-.81l.58-.76a8.615 8.615 0 0 0-3.842-1.238zm3.248 5.46a.667.667 0 0 1-.104.937l-2.04 1.631l.007.12c0 .692-.529 1.262-1.205 1.326l-.129.006a1.333 1.333 0 1 1 .558-2.544l1.976-1.58a.667.667 0 0 1 .937.104z"
              />
            </svg>
          ),
          name: "Social Monitoring",
          to: "/monitoramento-social",
        },
        {
          icon: (
            <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20"><path fill="currentColor" d="M3 5a2 2 0 0 1 2-2h8.379a2 2 0 0 1 1.414.586l1.621 1.621A2 2 0 0 1 17 6.621V15a2 2 0 0 1-2 2h-2.879l-1-1H14v-4.5a.5.5 0 0 0-.5-.5h-3.257a4.529 4.529 0 0 0-.914-1H13.5a1.5 1.5 0 0 1 1.5 1.5V16a1 1 0 0 0 1-1V6.621a1 1 0 0 0-.293-.707l-1.621-1.621A1 1 0 0 0 13.379 4H13v2.5A1.5 1.5 0 0 1 11.5 8h-4A1.5 1.5 0 0 1 6 6.5V4H5a1 1 0 0 0-1 1v4.757a4.523 4.523 0 0 0-1 .914V5Zm4-1v2.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5V4H7Zm3 9.5c0 .786-.26 1.512-.697 2.096l2.55 2.55a.5.5 0 0 1-.707.708l-2.55-2.55A3.5 3.5 0 1 1 10 13.5Zm-6 0a2.5 2.5 0 1 0 5 0a2.5 2.5 0 0 0-5 0Z"></path></svg>
          ),
          name: "Monitoramento Automatizado",
          to: "/monitoramento-automatizado",
        },
        {
          icon: (
            <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 256 256"><path d="M198 112a6 6 0 0 1-6 6h-40a6 6 0 0 1 0-12h40a6 6 0 0 1 6 6zm-6 26h-40a6 6 0 0 0 0 12h40a6 6 0 0 0 0-12zm-63.098 28.501a6 6 0 1 1-11.62 2.993a26.01 26.01 0 0 0-50.363.001a6 6 0 1 1-11.62-2.991a37.92 37.92 0 0 1 17.722-23.37a30 30 0 1 1 38.159 0a37.92 37.92 0 0 1 17.722 23.367zM92.101 138a18 18 0 1 0-18-18a18.02 18.02 0 0 0 18 18zM230 56v144a14.016 14.016 0 0 1-14 14H40a14.016 14.016 0 0 1-14-14V56a14.016 14.016 0 0 1 14-14h176a14.016 14.016 0 0 1 14 14zm-12 0a2.002 2.002 0 0 0-2-2H40a2.002 2.002 0 0 0-2 2v144a2.002 2.002 0 0 0 2 2h176a2.002 2.002 0 0 0 2-2z" fill="currentColor"></path></svg>
          ),
          name: "SuperaRJ",
          to: "/supera-rj",
        },
        {
          icon: (
            <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 256 256"><path d="M198 112a6 6 0 0 1-6 6h-40a6 6 0 0 1 0-12h40a6 6 0 0 1 6 6zm-6 26h-40a6 6 0 0 0 0 12h40a6 6 0 0 0 0-12zm-63.098 28.501a6 6 0 1 1-11.62 2.993a26.01 26.01 0 0 0-50.363.001a6 6 0 1 1-11.62-2.991a37.92 37.92 0 0 1 17.722-23.37a30 30 0 1 1 38.159 0a37.92 37.92 0 0 1 17.722 23.367zM92.101 138a18 18 0 1 0-18-18a18.02 18.02 0 0 0 18 18zM230 56v144a14.016 14.016 0 0 1-14 14H40a14.016 14.016 0 0 1-14-14V56a14.016 14.016 0 0 1 14-14h176a14.016 14.016 0 0 1 14 14zm-12 0a2.002 2.002 0 0 0-2-2H40a2.002 2.002 0 0 0-2 2v144a2.002 2.002 0 0 0 2 2h176a2.002 2.002 0 0 0 2-2z" fill="currentColor"></path></svg>
          ),
          name: "Recomeçar RJ",
          to: "/recomeca-rj",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              role="img"
              width="1em"
              height="1em"
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 32 32"
            >
              <path
                d="M16 5c-3.855 0-7 3.145-7 7c0 2.41 1.23 4.55 3.094 5.813C8.527 19.343 6 22.883 6 27h2c0-4.43 3.57-8 8-8s8 3.57 8 8h2c0-4.117-2.527-7.656-6.094-9.188A7.024 7.024 0 0 0 23 12c0-3.855-3.145-7-7-7zm0 2c2.773 0 5 2.227 5 5s-2.227 5-5 5s-5-2.227-5-5s2.227-5 5-5z"
                fill="currentColor"
              />
            </svg>
          ),
          name: "Minha conta",
          to: "/minha-conta",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              role="img"
              width="1em"
              height="1em"
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 16 16"
            >
              <g fill="currentColor">
                <path d="M7.5 1v7h1V1h-1z" />
                <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
              </g>
            </svg>
          ),
          name: "Sair",
          to: "/login",
        },
      ])
    } else if ( user.NivelAcesso === 'Usuario' ){
      setMenus([
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              role="img"
              width="1em"
              height="1em"
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 32 32"
            >
              <path
                d="M4 20v2h4.586L2 28.586L3.414 30L10 23.414V28h2v-8H4z"
                fill="currentColor"
              />
              <path d="M24 21h2v5h-2z" fill="currentColor" />
              <path d="M20 16h2v10h-2z" fill="currentColor" />
              <path d="M16 18h2v8h-2z" fill="currentColor" />
              <path
                d="M28 2H4a2.002 2.002 0 0 0-2 2v12h2v-3h24.001l.001 15H16v2h12a2.003 2.003 0 0 0 2-2V4a2.002 2.002 0 0 0-2-2zm-16 9H4V4h8zm2 0V4h14v7z"
                fill="currentColor"
              />
            </svg>
          ),
          name: "Dashboard",
          to: "/",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              role="img"
              width="1em"
              height="1em"
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 20 20"
            >
              <path
                fill="currentColor"
                d="M10 0c5.523 0 10 4.477 10 10s-4.477 10-10 10S0 15.523 0 10S4.477 0 10 0zm.667 1.359v1.035a.667.667 0 0 1-1.334 0V1.359A8.614 8.614 0 0 0 5.637 2.51l.522.584a.667.667 0 0 1-.995.888l-.63-.707a8.714 8.714 0 0 0-1.776 1.962l.843.506a.667.667 0 0 1-.686 1.143l-.803-.481a8.607 8.607 0 0 0-.709 2.491h.907a.667.667 0 1 1 0 1.334l-.973-.001v.031a8.627 8.627 0 0 0 .742 3.263l.836-.559a.667.667 0 0 1 .741 1.109l-.939.627A8.66 8.66 0 0 0 10 18.667a8.662 8.662 0 0 0 7.447-4.23l-1.132-.757a.667.667 0 0 1 .74-1.109l.989.661a8.633 8.633 0 0 0 .62-3.003H17.58a.667.667 0 0 1 0-1.333h1.017a8.608 8.608 0 0 0-.57-2.168l-.95.492a.667.667 0 1 1-.612-1.184l.965-.5a8.71 8.71 0 0 0-1.839-2.158l-.602.789a.667.667 0 1 1-1.06-.81l.58-.76a8.615 8.615 0 0 0-3.842-1.238zm3.248 5.46a.667.667 0 0 1-.104.937l-2.04 1.631l.007.12c0 .692-.529 1.262-1.205 1.326l-.129.006a1.333 1.333 0 1 1 .558-2.544l1.976-1.58a.667.667 0 0 1 .937.104z"
              />
            </svg>
          ),
          name: "Social Monitoring",
          to: "/monitoramento-social",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              role="img"
              width="1em"
              height="1em"
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 32 32"
            >
              <path
                d="M16 5c-3.855 0-7 3.145-7 7c0 2.41 1.23 4.55 3.094 5.813C8.527 19.343 6 22.883 6 27h2c0-4.43 3.57-8 8-8s8 3.57 8 8h2c0-4.117-2.527-7.656-6.094-9.188A7.024 7.024 0 0 0 23 12c0-3.855-3.145-7-7-7zm0 2c2.773 0 5 2.227 5 5s-2.227 5-5 5s-5-2.227-5-5s2.227-5 5-5z"
                fill="currentColor"
              />
            </svg>
          ),
          name: "Minha conta",
          to: "/minha-conta",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              role="img"
              width="1em"
              height="1em"
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 16 16"
            >
              <g fill="currentColor">
                <path d="M7.5 1v7h1V1h-1z" />
                <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
              </g>
            </svg>
          ),
          name: "Sair",
          to: "/login",
        },
      ])
    } else if ( user.NivelAcesso === 'Recupera' ){
      setMenus([
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              role="img"
              width="1em"
              height="1em"
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 32 32"
            >
              <path
                d="M4 20v2h4.586L2 28.586L3.414 30L10 23.414V28h2v-8H4z"
                fill="currentColor"
              />
              <path d="M24 21h2v5h-2z" fill="currentColor" />
              <path d="M20 16h2v10h-2z" fill="currentColor" />
              <path d="M16 18h2v8h-2z" fill="currentColor" />
              <path
                d="M28 2H4a2.002 2.002 0 0 0-2 2v12h2v-3h24.001l.001 15H16v2h12a2.003 2.003 0 0 0 2-2V4a2.002 2.002 0 0 0-2-2zm-16 9H4V4h8zm2 0V4h14v7z"
                fill="currentColor"
              />
            </svg>
          ),
          name: "Dashboard",
          to: "/",
        },
        {
          icon: (
            <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 256 256"><path d="M198 112a6 6 0 0 1-6 6h-40a6 6 0 0 1 0-12h40a6 6 0 0 1 6 6zm-6 26h-40a6 6 0 0 0 0 12h40a6 6 0 0 0 0-12zm-63.098 28.501a6 6 0 1 1-11.62 2.993a26.01 26.01 0 0 0-50.363.001a6 6 0 1 1-11.62-2.991a37.92 37.92 0 0 1 17.722-23.37a30 30 0 1 1 38.159 0a37.92 37.92 0 0 1 17.722 23.367zM92.101 138a18 18 0 1 0-18-18a18.02 18.02 0 0 0 18 18zM230 56v144a14.016 14.016 0 0 1-14 14H40a14.016 14.016 0 0 1-14-14V56a14.016 14.016 0 0 1 14-14h176a14.016 14.016 0 0 1 14 14zm-12 0a2.002 2.002 0 0 0-2-2H40a2.002 2.002 0 0 0-2 2v144a2.002 2.002 0 0 0 2 2h176a2.002 2.002 0 0 0 2-2z" fill="currentColor"></path></svg>
          ),
          name: "Recomeçar RJ",
          to: "/recomecar-rj",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              role="img"
              width="1em"
              height="1em"
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 32 32"
            >
              <path
                d="M16 5c-3.855 0-7 3.145-7 7c0 2.41 1.23 4.55 3.094 5.813C8.527 19.343 6 22.883 6 27h2c0-4.43 3.57-8 8-8s8 3.57 8 8h2c0-4.117-2.527-7.656-6.094-9.188A7.024 7.024 0 0 0 23 12c0-3.855-3.145-7-7-7zm0 2c2.773 0 5 2.227 5 5s-2.227 5-5 5s-5-2.227-5-5s2.227-5 5-5z"
                fill="currentColor"
              />
            </svg>
          ),
          name: "Minha conta",
          to: "/minha-conta",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              role="img"
              width="1em"
              height="1em"
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 16 16"
            >
              <g fill="currentColor">
                <path d="M7.5 1v7h1V1h-1z" />
                <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
              </g>
            </svg>
          ),
          name: "Sair",
          to: "/login",
        },
      ])
    }

  }, [user])

  return (
    <div id="layout">
      <div className={`sidebar ${menuOpened === true ? "opened" : "closed"}`}>
        <div className={`head`}>
          <a className="logo-default">Prefeitura de Mesquita</a>

          <button onClick={() => setMenuOpened(!menuOpened)}>
            {!menuOpened ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                role="img"
                width="1em"
                height="1em"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 16 16"
              >
                <g fill="currentColor">
                  <path d="M16 5H0V4h16v1zm0 8H0v-1h16v1zm0-4.008H0V8h16v.992z" />
                </g>
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                role="img"
                width="1em"
                height="1em"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 1024 1024"
              >
                <path
                  fill="currentColor"
                  d="M764.288 214.592L512 466.88L259.712 214.592a31.936 31.936 0 0 0-45.12 45.12L466.752 512L214.528 764.224a31.936 31.936 0 1 0 45.12 45.184L512 557.184l252.288 252.288a31.936 31.936 0 0 0 45.12-45.12L557.12 512.064l252.288-252.352a31.936 31.936 0 1 0-45.12-45.184z"
                />
              </svg>
            )}
          </button>
        </div>

        <SideBar
          pathname={props.location.pathname}
          data={menus}
        />
      </div>

      <div className="content">
        <header
          id="content-header"
          className={`${page.name}`}
        >
          <h1>{page.name}</h1>

          <div className="last">
            <Profile />
          </div>
        </header>

        <section id="content-page">{props.children}</section>
      </div>
    </div>
  );
}
