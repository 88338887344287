const axios = require('axios');

var baseUrl

if(process.env.NODE_ENV === 'development') {
  baseUrl = '/backend/'
} else if (process.env.NODE_ENV === 'test') {
  baseUrl = '/backend/'
} else {
  baseUrl = 'https://prefeiturademesquita.api.segmenta.app/api/'
}

const TOKEN = window.localStorage.getItem('token')

const RequestAPI = axios.create({
  baseURL: baseUrl,
  headers: {
    'Authorization': 'Bearer '+TOKEN
  }
})

export { RequestAPI, TOKEN }