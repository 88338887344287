import React from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'

import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'

import Layout from './Components/Layout/Layout'

import Home from './Pages/Home/Home'
import Login from './Pages/Login/Login'
import Profile from './Pages/Profile/Profile'
import MonitoramentoSocial from './Pages/MonitoramentoSocial/MonitoramentoSocial'
import NovaCampnha, { NovaCampanha } from './Pages/NovaCampanha/NovaCampanha'
import MonitoramentoAutomatizado from './Pages/MonitoramentoAutomatizado/MonitoramentoAutomatizado'
import SuperaRJ from './Pages/SuperaRJ/SuperaRJ'
import RecomecaRJ from './Pages/RecomecaRJ/RecomecaRJ'
import GlobalProvider from './Context/GlobalContext'
import MonitoramentoSocialSalvo from './Pages/MonitoramentoAutomatizado/MonitoramentoSocialSalvo'

//testing
export default function Routes() {

  function PrivateRoute({ children, ...rest }) {

    return (
      <Route
          {...rest}
          render={({ location }) =>
            window.localStorage.getItem('token') ? (
                children
            ) : (
              <Redirect
                to={{
                  pathname: "/login",
                  state: { from: location }
                }}
              />
            )
          }
      />
    )

  }

  return(

      <GlobalProvider>
        <Router>

          <Switch>

            <Route exact path="/login" component={Login}/>

            <Layout>
              
              <PrivateRoute exact path="/">
                <Route component={Home}/>
              </PrivateRoute>

              <PrivateRoute exact path="/monitoramento-social">
                <Route component={MonitoramentoSocial}/>
              </PrivateRoute>

              <PrivateRoute exact path="/monitoramento-automatizado">
                <Route component={MonitoramentoAutomatizado}/>
              </PrivateRoute>

              <PrivateRoute exact path="/monitoramento-social/:id">
                <Route component={MonitoramentoSocialSalvo}/>
              </PrivateRoute>

              <PrivateRoute exact path="/nova-campanha">
                <Route component={NovaCampanha}/>
              </PrivateRoute>

              <PrivateRoute exact path="/supera-rj">
                <Route component={SuperaRJ}/>
              </PrivateRoute>

              <PrivateRoute exact path="/recomeca-rj">
                <Route component={RecomecaRJ}/>
              </PrivateRoute>

              <PrivateRoute exact path="/minha-conta">
                <Route component={Profile}/>
              </PrivateRoute>

            </Layout>

          </Switch>

        </Router>
      </GlobalProvider>

    )

}