import React, { useEffect, useState } from 'react';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    Area,
    AreaChart,
} from 'recharts';

import './styles.scss';

function LinhaDoTempo({ data = {}, title = '' }) {
    const [dataChart, setDataChart] = useState([]);

    useEffect(() => {
        const newData = [];
        const postsTimeLine = Object.entries(data.posts_timeline);
        const postsTimeLineObj = data.posts_timeline;
        console.log(postsTimeLineObj);
        console.log(postsTimeLine);

        postsTimeLine.map((rowData, keyData) => {
            rowData[1].map((row, key) => {
                if (
                    newData.filter((obj) => obj.name === row.date).length === 0
                ) {
                    newData.push({
                        name: row.date,
                        facebook: postsTimeLineObj.facebook.filter(
                            (obj) => obj.date === row.date
                        )[0].count,

                        googleplus: postsTimeLineObj.googleplus.filter(
                            (obj) => obj.date === row.date
                        )[0].count,

                        instagram: postsTimeLineObj.instagram.filter(
                            (obj) => obj.date === row.date
                        )[0].count,

                        reddit: postsTimeLineObj.reddit.filter(
                            (obj) => obj.date === row.date
                        )[0].count,

                        tumblr: postsTimeLineObj.tumblr.filter(
                            (obj) => obj.date === row.date
                        )[0].count,

                        twitter: postsTimeLineObj.twitter.filter(
                            (obj) => obj.date === row.date
                        )[0].count,

                        vimeo: postsTimeLineObj.vimeo.filter(
                            (obj) => obj.date === row.date
                        )[0].count,

                        vine: postsTimeLineObj.vine.filter(
                            (obj) => obj.date === row.date
                        )[0].count,

                        vkontakte: postsTimeLineObj.vkontakte.filter(
                            (obj) => obj.date === row.date
                        )[0].count,

                        web: postsTimeLineObj.web.filter(
                            (obj) => obj.date === row.date
                        )[0].count,

                        youtube: postsTimeLineObj.youtube.filter(
                            (obj) => obj.date === row.date
                        )[0].count,
                    });
                }
            });
        });

        setDataChart([...newData]);
    }, [data]);

    useEffect(() => {
        const newDataIndice = [];
        dataChart.map((row, key) => {
            newDataIndice.push(row);
        });
        console.log(newDataIndice);
    }, []);

    return (
        <div className="linha-do-tempo">
            <div className="title-post">
                <h3>{title}</h3>
            </div>
            <div className="data">
                <ResponsiveContainer width="100%" height="100%">
                    <LineChart width={500} height={300} data={dataChart}>
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line
                            type="monotone"
                            dataKey="dailymotion"
                            stroke="#"
                        />
                        <Line
                            type="monotone"
                            dataKey="facebook"
                            stroke="#2e89ff"
                        />
                        <Line
                            type="monotone"
                            dataKey="googleplus"
                            stroke="#465a64"
                        />
                        <Line
                            type="monotone"
                            dataKey="instagram"
                            stroke="#a034aa"
                        />
                        <Line
                            type="monotone"
                            dataKey="reddit"
                            stroke="#0079d3"
                        />
                        <Line
                            type="monotone"
                            dataKey="tumblr"
                            stroke="#2e4c69"
                        />
                        <Line
                            type="monotone"
                            dataKey="twitter"
                            stroke="#2aa9e0"
                        />
                        <Line
                            type="monotone"
                            dataKey="vimeo"
                            stroke="#1ab7ea"
                        />
                        <Line type="monotone" dataKey="vine" stroke="#333" />
                        <Line
                            type="monotone"
                            dataKey="vkontakte"
                            stroke="#2787f5"
                        />
                        <Line type="monotone" dataKey="web" stroke="#CCC" />
                        <Line type="monotone" dataKey="youtube" stroke="#f00" />
                    </LineChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
}

export default LinhaDoTempo;
