import React from 'react';
import Post from './Post';
import PostEnglish from './PostEnglish';

// import { Container } from './styles';

function Posts({ data = [], title, type = "portuguese" }) {
    return (
        <div>
            <div className="title-post">
                <h3>
                    {title} {data.length}
                </h3>
            </div>
            <div className="posts">
                {data.map((row, key) => (
                    type === 'portuguese' ?
                    <Post item={row} key={key} />
                    :
                    <PostEnglish item={row} key={key}/>
                ))}
            </div>
        </div>
    );
}

export default Posts;
