import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CSVLink } from 'react-csv';

//components
import messageStore from '../../Store/MessageStore';
import { useGlobal } from '../../Context/GlobalContext';

//scss
import './MonitoramentoSocial.scss';
import LoadingGif from '../../Images/loadinggif.gif';
import InputDefault from '../../Components/Form/InputDefault';
import ButtonDefault from '../../Components/Form/ButtonDefault';
import Axios from 'axios';
import {
    IconFacebook,
    IconInstagram,
    IconTwitter,
    IconWeb,
    IconYoutube,
} from '../../Components/SVG/Icons';
import Post from '../../Components/Post/PostOld';
import Posts from '../../Components/Post/Posts';
import Sentimental from '../../Components/Sentimental';
import TotalSocial from '../../Components/TotalSocial';
import { RequestAPI } from '../../Api/app';
import LinhaDoTempo from '../../Components/LinhaDoTempo';
import Paginator from '../../Components/Paginator/Paginator';

export default function MonitoramentoSocialSalvo(props) {
    const history = useHistory();
    const [searchData, setSearchData] = useState({});
    const [submited, setSubmited] = useState(false);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(0)
    const [filter, setFilter] = useState('postagens');
    const { changePage } = useGlobal();
    const [load, setLoad] = useState(false);
    const [sociais, setSociais] = useState([]);
    const [dataPosts, setDataPosts] = useState([]);
    const [dataTime, setDataTime] = useState({});
    const [pagination, setPagination] = useState({})

    useEffect(() => {
        const dataLocation = props.location?.state?.data;
        setSearchData({ ...dataLocation });
        setSociais([...dataLocation.Redes.split(',')]);
        changePage('name', 'Social Monitoring Salvo');
        getData();
    }, [props, page]);

    async function handleSubmit(e) {
        e.preventDefault();
        setLoad(true);
        setLoad(false);
        setSubmited(true);
    }

    async function getData() {
        setLoad(true);
        console.log(props);
        //const response = await RequestAPI.get(
            //'DadosRedeSocial?id=' + props.match.params.id
        //);

        const response = await RequestAPI.post('BuscaRedeSocial', {
            id: props.match.params.id,
            Periodo:'last3months',
            Pagina: page.toString(),
            Limit: "10",
        })

        const chart = await RequestAPI.post('DadosRedeSocial', {
            id: props.match.params.id,
        });

        setDataTime({ ...chart.data?.ObjRetorno?.analytics });

        setPagination({...response.data.ObjRetorno})
        setDataPosts([...response.data.ObjRetorno.RetornoRedeSocialPaginado]);
        setLoad(false);
        setSubmited(true);
    }

    return (
        <div id="monitoramento-social-salvo">
            <div className="header-back">
                <button className="goback" onClick={() => history.goBack()}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        role="img"
                        width="1em"
                        height="1em"
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 24 24"
                    >
                        <path
                            fill="none"
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="m15 4l-8 8l8 8"
                        />
                    </svg>
                    <span>Voltar</span>
                </button>
            </div>
            <form className="search-social" onSubmit={handleSubmit}>
                <h4>Origem da busca</h4>
                <div className="social-list">
                    <button
                        type="button"
                        className={`social ${
                            sociais.filter((obj) => obj === 'facebook').length
                                ? 'active'
                                : 'inactive'
                        }`}
                    >
                        <IconFacebook />
                        <span>Facebook</span>
                    </button>
                    <button
                        type="button"
                        className={`social ${
                            sociais.filter((obj) => obj === 'twitter').length
                                ? 'active'
                                : 'inactive'
                        }`}
                    >
                        <IconTwitter />
                        <span>Twitter</span>
                    </button>
                    <button
                        type="button"
                        className={`social ${
                            sociais.filter((obj) => obj === 'youtube').length
                                ? 'active'
                                : 'inactive'
                        }`}
                    >
                        <IconYoutube />
                        <span>Youtube</span>
                    </button>
                    <button
                        type="button"
                        className={`social ${
                            sociais.filter((obj) => obj === 'instagram').length
                                ? 'active'
                                : 'inactive'
                        }`}
                    >
                        <IconInstagram />
                        <span>Instagram</span>
                    </button>
                    <button
                        type="button"
                        className={`social ${
                            sociais.filter((obj) => obj === 'web').length
                                ? 'active'
                                : 'inactive'
                        }`}
                    >
                        <IconWeb />
                        <span>Sites</span>
                    </button>
                </div>

                <div className="input-container">
                    <InputDefault
                        placeholder="Palavra chave"
                        value={searchData.Busca}
                        onChange={(name, value) => setSearch(value)}
                    />
                    {load === false &&
                        submited === true &&
                        dataPosts.length > 0 && (
                            <div style={{ marginLeft: 10 }}>
                                <ButtonDefault type="button">
                                    <CSVLink
                                        filename={'monitoramento-social.csv'}
                                        data={dataPosts}
                                        headers={[
                                            {
                                                label: 'Rede Social',
                                                key: 'RedeSocial',
                                            },
                                            {
                                                label: 'Sentimento',
                                                key: 'Sentimento',
                                            },
                                            {
                                                label: 'Citação',
                                                key: 'Citacao',
                                            },
                                            {
                                                label: 'Autor',
                                                key: 'Autor',
                                            },
                                            {
                                                label: 'Data e hora da postagem',
                                                key: 'DataHora',
                                            },
                                            {
                                                label: 'Link da postagem',
                                                key: 'LinkPostagem',
                                            },
                                            {
                                                label: 'Visualizações',
                                                key: 'Visualizacoes',
                                            },
                                            {
                                                label: 'Compartilhamentos',
                                                key: 'Compartilhamentos',
                                            },
                                            {
                                                label: 'Comentarios',
                                                key: 'Comentarios',
                                            },
                                            {
                                                label: 'Curtidas',
                                                key: 'Curtidas',
                                            },
                                            {
                                                label: 'Localidade',
                                                key: 'Localidade',
                                            },
                                        ]}
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                            aria-hidden="true"
                                            role="img"
                                            width="1em"
                                            height="1em"
                                            preserveAspectRatio="xMidYMid meet"
                                            viewBox="0 0 36 36"
                                        >
                                            <path
                                                d="M30.31 13v-.32a10.26 10.26 0 0 0-10.45-10a10.47 10.47 0 0 0-9.6 6.1A9.65 9.65 0 0 0 10.89 28a3 3 0 0 1 0-2A7.65 7.65 0 0 1 11 10.74h.67l.23-.63a8.43 8.43 0 0 1 8-5.4a8.26 8.26 0 0 1 8.45 8a7.75 7.75 0 0 1 0 .8l-.08.72l.65.3A6 6 0 0 1 26.38 26h-1.29a3 3 0 0 1 0 2h1.28a8 8 0 0 0 3.93-15z"
                                                class="clr-i-outline clr-i-outline-path-1"
                                                fill="currentColor"
                                            />
                                            <path
                                                d="M22.28 26.07a1 1 0 0 0-.71.29L19 28.94V16.68a1 1 0 1 0-2 0v12.26l-2.57-2.57A1 1 0 0 0 13 27.78l5 5l5-5a1 1 0 0 0-.71-1.71z"
                                                class="clr-i-outline clr-i-outline-path-2"
                                                fill="currentColor"
                                            />
                                        </svg>
                                    </CSVLink>
                                </ButtonDefault>
                            </div>
                        )}

                    {load === false &&
                        submited === true &&
                        dataPosts.length > 0 && (
                            <div style={{ marginLeft: 10 }}>
                                <ButtonDefault
                                    onClick={() => window.print()}
                                    type="button"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        aria-hidden="true"
                                        role="img"
                                        width="1em"
                                        height="1em"
                                        preserveAspectRatio="xMidYMid meet"
                                        viewBox="0 0 32 32"
                                    >
                                        <g
                                            fill="none"
                                            stroke="currentColor"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                        >
                                            <path d="M7 25H2V9h28v16h-5M7 19v11h18V19zM25 9V2H7v7m15 5h3" />
                                        </g>
                                    </svg>
                                </ButtonDefault>
                            </div>
                        )}
                </div>
            </form>

            {submited && dataPosts.length > 0 && (
                <div className="filter">
                    <button
                        onClick={() => setFilter('postagens')}
                        className={
                            filter === 'postagens' ? 'active' : 'inactive'
                        }
                    >
                        Postagens
                    </button>
                    <button
                        onClick={() => setFilter('linhaDoTempo')}
                        className={
                            filter === 'linhaDoTempo' ? 'active' : 'inactive'
                        }
                    >
                        Linha do tempo
                    </button>
                    <button
                        onClick={() => setFilter('sentimentos')}
                        className={
                            filter === 'sentimentos' ? 'active' : 'inactive'
                        }
                    >
                        Análise de sentimentos
                    </button>
                    <button
                        onClick={() => setFilter('engajamento')}
                        className={
                            filter === 'engajamento' ? 'active' : 'inactive'
                        }
                    >
                        Relatório de engajamento
                    </button>
                </div>
            )}

            {load === true && (
                <div class="loading-container">
                    <img src={LoadingGif} />
                </div>
            )}

            {filter === 'linhaDoTempo' && submited === true && load === false && (
                <>
                    <LinhaDoTempo data={dataTime} title="Linha do tempo" />
                </>
            )}

            {filter === 'engajamento' && submited === true && load === false && (
                <>
                    <TotalSocial
                        data={dataPosts}
                        title="Relatório de engajamento"
                        sociais={sociais}
                    />
                </>
            )}

            {filter === 'sentimentos' && submited === true && load === false && (
                <>
                    <Sentimental
                        data={dataPosts}
                        title="Análise de sentimentos"
                        sociais={sociais}
                    />
                </>
            )}

            {filter === 'postagens' && submited === true && load === false && (
                <>
                    <Posts
                        load={load}
                        title="Postagens recentes"
                        data={dataPosts}
                    />
                </>
            )}

            { submited === true && 
                <div className="table-default">
                    <table>
                        <tfoot>
                            <tr>
                                <td colSpan={4}>
                                    <div className="paginator">
                                        <ul>
                                            <li>
                                                <button className={page === 0 ? 'inactive' : 'normal'} type="button" onClick={() => page != 0 && setPage(page - 1)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"><g fill="currentColor"><path fill-rule="evenodd" d="M8.354 1.646a.5.5 0 0 1 0 .708L2.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/><path fill-rule="evenodd" d="M12.354 1.646a.5.5 0 0 1 0 .708L6.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/></g></svg>
                                                </button>
                                            </li>

                                            <li>
                                                <button className={pagination?.NextPage === -1 ? 'inactive' : 'normal'} type="button" onClick={() => pagination.NextPage != -1 && setPage(page + 1)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"><g fill="currentColor"><path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8L3.646 2.354a.5.5 0 0 1 0-.708z"/><path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8L7.646 2.354a.5.5 0 0 1 0-.708z"/></g></svg>
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                  </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            }
        </div>
    );
}
